import React, { useState, useEffect } from "react";
import { Box, Button, CircularProgress } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewComfyIcon from '@mui/icons-material/ViewComfy';
import Pagination from '@mui/material/Pagination';
import { Tab, Tabs } from "@mui/material";
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import Swal from "sweetalert2";

import TopBar from "../../components/TopBar/TopBar";
import DropDown from "../../components/DropDown/DropDown";
import SubjectDropDown from "../../components/DropDown/SubjectDropDown";
import SearchFieldCard from "../../components/SearchFieldCard/SearchFieldCard";
import TabPanel from "../../components/TabPanel/TabPanel";
import ListViewResults from "../ListViewResults/ListViewResults";
import GridViewResults from "../GridViewResults/GridViewResults";

import { getAllSubjectOnes, getAllSubjectTwos, getAllTopics, getOneSubjectOne, getOneSubjectTwo, getOneOrgan, getAllOrgans, getOneTopic } from '../../services/axios/masterDataAPI'
import { getAllPY2Data, getAllPY1Data, getAllPY1DataNoCourseFilter, getAllPY2DataNoCourseFilter } from '../../services/axios/getRequests'
import yearData from '../../services/data/yearData'
import { getCompetencyCode } from "../../services/helper-functions/subject_to_competency_code";
import { validatePY1Search, validatePY2Search } from '../../services/helper-functions/validateSearch'

import './Home.scss'
import { ElevatorSharp } from "@mui/icons-material";

const Home = () => {

    const initial = {
        year: "",
        course_type: 1,
        organ: "",
        topic_name: "",
        subject: "",
        competency_number: "",
        search: "",
        page_size: 1000
    };
    const [inputs, setInputs] = useState(initial)
    const [gotResponse, setGotResponse] = useState(true)

    const [digitalLearningResults, setDigitalLearningResults] = useState([])
    const [digitalLearningTotalResultsCount, setDigitalLearningTotalResultsCount] = useState(-1)

    const [lectureCapsuleResults, setLectureCapsuleResults] = useState([])
    const [lectureCapsuleTotalResultsCount, setLectureCapsuleTotalResultsCount] = useState(-1)

    const [subjects, setSubjects] = useState('')
    const [organName, setOrganName] = useState('')
    const [organs, setOrgans] = useState('')
    const [topicName, setTopicName] = useState('')
    const [topics, setTopics] = useState('')
    const [page, setPage] = useState(1)
    const [viewType, setViewType] = useState('grid')
    const [allResults, setAllResults] = useState([])
    const [showingResults, setShowingResults] = useState([])
    const [allPageCount, setAllPageCount] = useState('')
    const [isSearched, setIsSearched] = useState(false)

    const competency_tooltip = "Search competency number"

    useEffect(() => {
        fetchAllSubjectData()
    }, [])

    useEffect(() => {

        if (inputs.year === 1) {
            getAllSubjectOnes().then(res => {
                if (res.success) {
                    setSubjects(res.data.results)
                }
            })
            getAllOrgans().then(res => {
                if (res.success) {

                    let organFilterArray = res.data.results.filter(item => {
                        if (inputs.year === 1) {
                            return item.year.id === 1 || item.year.id === 2
                        } else if (inputs.year === 2) {
                            return item.year.id === 3 || item.year.id === 4
                        }
                    })
                    setOrgans(organFilterArray)
                }
            })
        }

        if (inputs.year == 2) {
            getAllSubjectTwos().then(res => {
                if (res.success) {
                    setSubjects(res.data.results)
                }
            })

            getAllTopics().then(res => {
                if (res.success) {
                    setTopics(res.data.results)
                }
            })

        }

    }, [inputs.year])

    useEffect(() => {
        setInputs({
            ...inputs,
        })
        if (inputs.year === 2) {

            getAllTopics(inputs.subject).then(res => {
                if (res.success) {
                    setTopics(res.data.results)
                }
            })
        }
    }, [inputs.subject])

    useEffect(() => {
        if (inputs.year === 1) {
            getAllSubjectOnes(organName).then(res => {
                if (res.success) {
                    setSubjects(res.data.results)
                }
            })
        }
    }, [organName])


    const fetchAllSubjectData = () => {
        let temp = []

        getAllSubjectOnes().then(res => {
            if (res.success) {
                temp = [...res.data.results]
                getAllSubjectTwos().then(res => {
                    if (res.success) {
                        temp = [...temp, ...res.data.results]
                    }
                    setSubjects(temp)
                })
            }
        })
    }

    const fetchOrganName = (id) => {
        getOneOrgan(id).then(res => {
            if (res.success) {
                setOrganName(res.data.name)
            }
        })
    }

    const fetchTopicName = (id) => {
        getOneTopic(id).then(res => {
            if (res.success) {
                setTopicName(res.data.name)
            }
        })
    }

    const handleOnChange = (e) => {

        setInputs({
            ...inputs,
            [e.target.name]: e.target.value
        })

        if (e.target.name === "organ") {
            fetchOrganName(e.target.value)
        }

        if (e.target.name === "topic_name") {
            fetchTopicName(e.target.value)
        }
    }

    useEffect(() => {
        if (isSearched) {
            if (digitalLearningTotalResultsCount == 0 && lectureCapsuleTotalResultsCount == 0) {
                Swal.fire({
                    position: 'top-start',
                    icon: 'info',
                    title: 'No results found',
                    showConfirmButton: false,
                    timer: 1000
                })
            }
        }
    }, [digitalLearningTotalResultsCount, lectureCapsuleTotalResultsCount])


    useEffect(() => {

        let tempResults = [...digitalLearningResults, ...lectureCapsuleResults]


        setAllResults(tempResults)

        if (viewType == 'grid') {
            setAllPageCount(Math.ceil(tempResults.length / 12))
            setShowingResults(tempResults.slice(12 * (page - 1), 12 * (page - 1) + 12))
        } else {
            setAllPageCount(Math.ceil(tempResults.length / 5))
            setShowingResults(tempResults.slice(5 * (page - 1), 5 * (page - 1) + 5))
        }

    }, [digitalLearningResults, lectureCapsuleResults])


    const fetchPY1Data = (inputs) => {

        getAllPY1Data({ ...inputs, organName, course_type: 1 }).then(res => {
            setGotResponse(true)
            if (res.success) {
                setDigitalLearningTotalResultsCount(res.data.count)
                setDigitalLearningResults(res.data.results)
            }
        })
        getAllPY1Data({ ...inputs, organName, course_type: 2, }).then(res => {
            setGotResponse(true)
            if (res.success) {
                setLectureCapsuleTotalResultsCount(res.data.count)
                setLectureCapsuleResults(res.data.results)
            }
        })

    }

    const fetchPY2Data = (inputs) => {

        getAllPY2Data({ ...inputs, topicName, course_type: 1 }).then(res => {
            setGotResponse(true)
            if (res.success) {
                setDigitalLearningTotalResultsCount(res.data.count)
                setDigitalLearningResults(res.data.results)
            }
        })
        getAllPY2Data({ ...inputs, topicName, course_type: 2 }).then(res => {
            setGotResponse(true)
            if (res.success) {
                setLectureCapsuleTotalResultsCount(res.data.count)
                setLectureCapsuleResults(res.data.results)
            }
        })




    }

    const fetchAllData = (inputs) => {

        getAllPY1DataNoCourseFilter({ ...inputs, organName }).then(res => {
            setGotResponse(true)
            if (res.success) {
                setDigitalLearningTotalResultsCount(res.data.count)
                setDigitalLearningResults(res.data.results)
            }
        })

        getAllPY2DataNoCourseFilter({ ...inputs, }).then(res => {
            setGotResponse(true)
            if (res.success) {
                setLectureCapsuleTotalResultsCount(res.data.count)
                setLectureCapsuleResults(res.data.results)
            }
        })
    }

    const handleSubmit = () => {
        setPage(1)
        if (!inputs.year) {
            setGotResponse(false)
            setIsSearched(true)
            fetchAllData(inputs)
        }
        if (inputs.year === 1) {
            setGotResponse(false)
            setIsSearched(true)
            fetchPY1Data(inputs)
        }
        if (inputs.year === 2) {
            setGotResponse(false)
            setIsSearched(true)
            fetchPY2Data(inputs)
        }

    }

    const handleResetSearch = () => {
        setInputs(initial)
        setIsSearched(false)
        fetchAllSubjectData()
        setOrganName('')
        setTopicName('')
        setDigitalLearningResults([])
        setLectureCapsuleResults([])
        setDigitalLearningTotalResultsCount(-1)
        setLectureCapsuleTotalResultsCount(-1)
        setPage(1)
    }

    const handleOnPageChange = (event, value) => {
        if (viewType == 'grid') {

            setShowingResults(allResults.slice(12 * (value - 1), (12 * (value - 1) + 12)))
        } else {
            setShowingResults(allResults.slice(5 * (value - 1), (5 * (value - 1) + 5)))

        }
        setPage(value);
    }

    const handleViewChange = (view) => {
        setPage(1)
        if (view == 'grid') {
            setAllPageCount(Math.ceil(allResults.length / 12))
        } else {
            setAllPageCount(Math.ceil(allResults.length / 5))
        }
        setViewType(view)
    }


    return (
        <>
            <TopBar />
            <Box sx={{ width: '100%' }} className="_home_main_container">
                <Box className="_formContainer" >
                    < form action="">
                        <DropDown
                            isCompulsory={false}
                            name="year"
                            value={inputs.year}
                            label="Year"
                            menu={yearData && yearData}
                            onChange={handleOnChange}

                        />
                        {
                            inputs.year === 1 ?
                                <DropDown
                                    name="organ"
                                    value={inputs.organ}
                                    label="Organ"
                                    menu={organs && organs}
                                    onChange={handleOnChange}

                                /> : <></>
                        }
                        <SubjectDropDown
                            label="Subject"
                            name="subject"
                            value={inputs.subject}
                            menu={subjects && subjects}
                            onChange={handleOnChange}
                        />
                        {

                            inputs.year === 2 ?
                                <DropDown
                                    name="topic_name"
                                    value={inputs.topic_name}
                                    label="Topic Name"
                                    menu={topics && topics}
                                    onChange={handleOnChange}

                                />
                                : <></>
                        }
                        <SearchFieldCard
                            tooltip={competency_tooltip}
                            name="competency_number"
                            value={inputs.competency_number}
                            placeholder="Search competency number"
                            onChange={handleOnChange}
                        />

                        <SearchFieldCard
                            tooltip="Type any keywords"
                            name="search"
                            value={inputs.search}
                            placeholder="Search keywords, display name, links ..."
                            onChange={handleOnChange}
                        />
                        {
                            gotResponse ?
                                <Box style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                                    {
                                        <Button
                                            type="submit"
                                            sx={{
                                                textTransform: 'capitalize',
                                                width: "50%",
                                                marginRight: "10px"
                                            }}
                                            variant="contained"
                                            startIcon={<SearchIcon />}
                                            onClick={handleSubmit}
                                        >
                                            Search
                                        </Button>

                                    }

                                    <Button
                                        sx={{
                                            textTransform: 'capitalize',
                                            width: "50%"
                                        }}
                                        variant="contained"
                                        startIcon={<RestartAltIcon />}
                                        onClick={handleResetSearch}
                                    >
                                        Reset Search
                                    </Button>
                                </Box>
                                : <div style={{ marginTop: '20px', textAlign: 'center' }}>
                                    <CircularProgress />
                                </div>

                        }

                    </form>
                </Box>
                <Box className="_resultContainer">

                    <div className="_results_section_container">
                        {
                            isSearched ?

                                <>
                                    <h3 style={{ color: '#2196f3' }}><SearchIcon style={{ verticalAlign: 'sub' }} /> {allResults.length && allResults.length} search results</h3>
                                    <div className="_result_cards_collection_container"
                                    >
                                        <Box style={{
                                            height: '75vh',
                                            overflowY: 'auto'
                                        }}>
                                            {
                                                viewType === 'list' ? <ListViewResults allResults={showingResults} /> :
                                                    viewType === 'grid' ?
                                                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                            <GridViewResults allResults={showingResults} />
                                                        </div> : <></>
                                            }

                                        </Box>
                                        <Box style={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            width: '100%'
                                        }}>
                                            <Pagination
                                                sx={{ pt: 2 }}
                                                page={page}
                                                count={allPageCount}
                                                className="_pagination_container"
                                                color="primary"
                                                onChange={handleOnPageChange}
                                            />
                                            <Box style={{
                                                float: 'right',

                                            }}>
                                                <ViewListIcon
                                                    onClick={() => handleViewChange('list')}
                                                    name="list"
                                                    sx={{ mt: 2, ml: 2, color: '#2196f3', cursor: 'pointer' }}
                                                    style={viewType === 'list' ? { border: '1px solid #2196f3' } : {}}
                                                />
                                                <ViewComfyIcon
                                                    onClick={() => handleViewChange('grid')}
                                                    name="grid"
                                                    sx={{ mt: 2, ml: 2, color: '#2196f3', cursor: 'pointer' }}
                                                    style={viewType === 'grid' ? { border: '1px solid #2196f3' } : {}}
                                                />
                                            </Box>

                                        </Box>

                                    </div>
                                </>
                                : <div className="_empty_results_section" style={{ width: '100%' }}>
                                    <h4 style={{ display: 'flex' }}>
                                        <SearchIcon style={{ width: '20px', height: '20px' }} />
                                        <p style={{ marginLeft: '10px' }}>Search for results</p>
                                    </h4>
                                </div>
                        }
                    </div>


                </Box>
            </Box>
        </>
    )
}

export default Home;