import axios from './index'

export const getAllSubjectOnes = (organName) => {
    return axios.get('/subjectsone/', {
        params: {
            organ__name__icontains: organName,
            page_size: 1000
        }
    })
        .then(res => res.data)
        .catch(e => e.response.data)
}

export const getAllSubjectTwos = () => {
    return axios.get('/subjectstwo/', {
        params: {
            page_size: 1000
        }
    })
        .then(res => res.data)
        .catch(e => e.response.data)
}

export const getAllOrgans = () => {
    return axios.get('/organ/', {
        params: {
            page_size: 1000
        }
    })
        .then(res => res.data)
        .catch(e => e.response.data)
}

export const getAllTopics = (subjectName) => {
    return axios.get('/topic/', {
        params: {
            subjects__name__icontains: subjectName,
            page_size: 1000
        }
    })
        .then(res => res.data)
        .catch(e => e.response.data)
}

export const getOneSubjectOne = (id) => {
    return axios.get(`/subjectsone/${id}/`)
        .then(res => res.data)
        .catch(e => e.response.data)
}
export const getOneSubjectTwo = (id) => {
    return axios.get(`/subjectstwo/${id}/`)
        .then(res => res.data)
        .catch(e => e.response.data)
}

export const getOneOrgan = (id) => {
    return axios.get(`/organ/${id}/`)
        .then(res => res.data)
        .catch(e => e.response.data)
}

export const getOneTopic = (id) => {
    return axios.get(`/topic/${id}/`)
        .then(res => res.data)
        .catch(e => e.response.data)
}




