import axios from './index'

export const getAllPY1Data = (inputs) => {
    return axios.get('/year_1/',
        {
            params: {
                course_type: inputs.course_type && inputs.course_type,
                organ__name: inputs.organName && inputs.organName,
                subject__name: inputs.subject && inputs.subject,
                competency_number: inputs.competency_number ? `{${inputs.competency_number && inputs.competency_number}}` : "",
                search: inputs.search && inputs.search,
                page_size: inputs.page_size && inputs.page_size
            },

        }
    )
        .then(res => res.data)
        .catch(e => e.response.data)
}

export const getAllPY2Data = (inputs) => {
    return axios.get('/year_2/',
        {
            params: {
                course_type: inputs.course_type && inputs.course_type,
                topic_name__name: inputs.topicName && inputs.topicName,
                subject__name: inputs.subject && inputs.subject,
                competency_number: inputs.competency_number ? `{${inputs.competency_number && inputs.competency_number}}` : "",
                search: inputs.search && inputs.search,
                page_size: inputs.page_size && inputs.page_size
            },

        }
    )
        .then(res => res.data)
        .catch(e => e.response.data)
}

export const getAllPY1DataNoCourseFilter = (inputs) => {
    return axios.get('/year_1/',
        {
            params: {
                organ__name: inputs.organName && inputs.organName,
                subject__name: inputs.subject && inputs.subject,
                competency_number: inputs.competency_number ? `{${inputs.competency_number && inputs.competency_number}}` : "",
                search: inputs.search && inputs.search,
                page_size: inputs.page_size && inputs.page_size
            },
        }
    )
        .then(res => res.data)
        .catch(e => e.response.data)
}

export const getAllPY2DataNoCourseFilter = (inputs) => {
    return axios.get('/year_2/', {
        params: {
            topic_name: inputs.topic_name && inputs.topic_name,
            subject__name: inputs.subject && inputs.subject,
            competency_number: inputs.competency_number ? `{${inputs.competency_number && inputs.competency_number}}` : "",
            search: inputs.search && inputs.search,
            page_size: inputs.page_size && inputs.page_size
        },
    }
    )
        .then(res => res.data)
        .catch(e => e.response.data)
}
