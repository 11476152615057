import React from "react";
import { Select, MenuItem, InputLabel, FormControl, Card } from '@mui/material';

import './DropDown.scss'

const DropDown = ({ label, menu, value, name, onChange, dependency, isCompulsory }) => {

    return (
        <>
            <Card
                className="_drop_down_card_container"
                sx={{
                    marginTop: 2
                }}
                elevation={2}
            >
                <div style={{ padding: '10px' }}>

                    <FormControl variant="standard" sx={{ m: 1, minWidth: 150 }} size="small">
                        <InputLabel variant="standard" id="standard-label">
                            {isCompulsory ? <>{label}<span style={{ color: "red" }}>*</span></> : <>{label}</>}</InputLabel>
                        <Select
                            autoWidth={true}
                            labelId="standard-label"
                            id="standard"
                            value={value}
                            name={name}
                            onChange={onChange}
                            label="standard-label"
                            sx={{ width: '200px' }}
                            MenuProps={{ PaperProps: { sx: { maxHeight: 300, } } }}
                        >
                            {
                                menu &&
                                    menu.length > 0 ?
                                    menu.map((item, index) => {
                                        return (
                                            <MenuItem key={index} value={item.name}>{item.name}</MenuItem>
                                        )

                                    })
                                    : <MenuItem key={0} disabled>{dependency}</MenuItem>
                            }

                        </Select>
                    </FormControl>
                </div>
            </Card>

        </>
    )
}

export default DropDown;